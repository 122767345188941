@import "./fonts/Fonts.css";

.daredevil-red {
  color: #dc0000;
}

.daredevil-red-bg {
  background-color: #dc0000;
}

.nav-avatar {
  height: 40px;
}

.nav-icon {
  font-size: 1.50rem;
}

.footer-icon {
  font-size: 1.50rem;
}

.quote-icon {
  font-size: 1.25rem;
  color: #dc0000;
}

div.service {
  position: absolute;
}

.service-icon {
  font-size: 3.00rem;
  color: #dc0000;
}

.service-front-text {
  z-index: 1!important;
  background: rgba(255,255,255,0.9);
}

.service-front-icon {
  font-size: 2.50rem;
  z-index: 1!important;
}

.service-back-icon {
  font-size: 5.00rem;
  z-index: 0!important;
  color: #dc0000;
}

.footer-icon {
  font-size: 3.00rem;
  color: #dc0000;
}

.ig-image {
}

.ig-overlay {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0,0,0,0.5);
  width: 100%;
  height: 100%;
  visibility: hidden;
}

.ig-image:hover .ig-overlay{
  visibility: visible;
}

header {
  margin: 0px;
  padding: 0px;
}

.derp {
}

div.marketing {
  background-color: white;
}

div.spacer {
}

div.footer {
  background: rgb(18, 18, 18, 0.9);
}

.call-us-button {
  background: rgba(220,0,0,0.2);
}

.call-us-button:hover {
  background: rgba(220,0,0,0.5);
}

.call-us {
  background: rgba(0,0,0,0.7);
}

a.navbar-brand:hover {
  color: white!important;
}

a.nav-link:hover {
  color: white!important;
}

a.nav-link-bright {
  color: white!important;
}

a.nav-link-bright:hover {
  color: black!important;
}

.w-90 {
  width: 90%!important;
}

body {
  background-color: #060607;
  background-color: #060607;
  background-image: url("./images/racetrack3.png");
  background-position: 0% 90%;
  background-size: cover;
  background-attachment: fixed;
  font-family: Raleway, sans-serif;
}

main {
}

.content {
}
