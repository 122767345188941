@font-face {
  font-family: 'Montserrat';
  src: url('montserrat/montserrat-bold-webfont.eot');
  src: url('montserrat/montserrat-bold-webfont.eot?#iefix') format('embedded-opentype'),
       url('montserrat/montserrat-bold-webfont.svg#montserratbold') format('svg'),
       url('montserrat/montserrat-bold-webfont.woff') format('woff'),
       url('montserrat/montserrat-bold-webfont.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;

}




@font-face {
  font-family: 'Montserrat';
  src: url('montserrat/montserrat-regular-webfont.eot');
  src: url('montserrat/montserrat-regular-webfont.eot?#iefix') format('embedded-opentype'),
       url('montserrat/montserrat-regular-webfont.woff') format('woff'),
       url('montserrat/montserrat-regular-webfont.ttf') format('truetype'),
       url('montserrat/montserrat-regular-webfont.svg#montserratregular') format('svg');
  font-weight: 400;
  font-style: normal;

}


@font-face {
  font-family: 'Raleway';
  src: url('raleway/raleway-regular-webfont.eot');
  src: url('raleway/raleway-regular-webfont.eot?#iefix') format('embedded-opentype'),
       url('raleway/raleway-regular-webfont.woff') format('woff'),
       url('raleway/raleway-regular-webfont.ttf') format('truetype'),
       url('raleway/raleway-regular-webfont.svg#ralewayregular') format('svg');
  font-weight: 400;
  font-style: normal;

}




@font-face {
  font-family: 'Raleway';
  src: url('raleway/raleway-light-webfont.eot');
  src: url('raleway/raleway-light-webfont.eot?#iefix') format('embedded-opentype'),
       url('raleway/raleway-light-webfont.woff') format('woff'),
       url('raleway/raleway-light-webfont.ttf') format('truetype'),
       url('raleway/raleway-light-webfont.svg#ralewaylight') format('svg');
  font-weight: 300;
  font-style: normal;

}




@font-face {
  font-family: 'Raleway';
  src: url('raleway/raleway-extralight-webfont.eot');
  src: url('raleway/raleway-extralight-webfont.eot?#iefix') format('embedded-opentype'),
       url('raleway/raleway-extralight-webfont.svg#ralewayextralight') format('svg'),
       url('raleway/raleway-extralight-webfont.woff') format('woff'),
       url('raleway/raleway-extralight-webfont.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;

}

h2 small {
  font-family: Raleway, sans-serif;
}

h2 {
  font-family: Montserrat, sans-serif;
}

h4 small {
  font-family: Raleway, sans-serif;
}

h4 {
  font-family: Montserrat, sans-serif;
  font-size: 1.5rem;
}

p {
  font-family: Raleway, sans-serif;
}

strong {
  font-family: Montserrat, sans-serif;
}

blockquote p strong {
  font-family: Montserrat, sans-serif;
  color: #dc0000;
  size: 10.0rem;
}

@media (min-width: 180px) {
h1 {
	font-size: 32px;

}
h1 small {
	font-size: 32px;
}
.lead {
	font-size: 16px;
	font-weight: 600;
}
h2 {
	font-size: 30px;
	letter-spacing: -2px;

}
h2 small {
	font-size: 30px;
}
h3 {
	font-size: 20px;
}
h3 small {
	font-size: 20px;	
}
h5 small {
	font-size: 13px;
}
}
@media (min-width: 480px) {
h1 {
	font-size: 42px;

}
h1 small {
	font-size: 42px;
}
.lead {
	font-size: 16px;
	font-weight: 600;
}
h2 {
	font-size: 40px;
	letter-spacing: -2px;

}
h2 small {
	font-size: 40px;
}
h3 {
	font-size: 34px;
}
h3 small {
	font-size: 34px;	
}
}
@media (min-width: 768px) {
h1 {
	font-size: 56px;

}
h1 small {
	font-size: 56px;
}
.lead {
	font-size: 18px;
	font-weight: 600;
}
h2 {
	font-size: 50px;
	letter-spacing: -2px;

}
h2 small {
	font-size: 50px;
}
}
@media (min-width: 992px) {
h1 {
	font-size: 56px;

}
h1 small {
	font-size: 56px;
}
.lead {
	font-size: 20px;
	font-weight: 600;
}
h2 {
	font-size: 50px;
	letter-spacing: -3px;

}
h2 small {
	font-size: 50px;
}
}
@media (min-width: 1024px) {
h1 {
	font-size: 56px;

}
h1 small {
	font-size: 56px;
}
.lead {
	font-size: 20px;
	font-weight: 600;
}
h2 {
	font-size: 50px;
	letter-spacing: -3px;

}
h2 small {
	font-size: 50px;
}
h4 {
  font-size: 20px;
}
}
@media (min-width: 1200px) {
h1 {
	font-size: 78px;
	letter-spacing: -4px;
}
h1 small {
	font-size: 66px;
}
.lead {
	font-size: 20px;
	font-weight: 600;
}
h2 {
	font-size: 50px;
	letter-spacing: -3px;

}
h2 small {
	font-size: 50px;
}
h4 {
  font-size: 1.5rem;
}
}
